import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { laranja, orange, white } from 'src/styles/colors'

export const HeroSection = styled.section`
  padding: 32px 0;

  h1 {
    color: ${laranja.terra};
  }

`

export const Button = styled.button`
    background: ${orange.extra};
    border:none;
    border-radius:8px;
    width:100%;
    height:48px;
    color: ${white};
    font-size:14px;
    line-height:17px;
    font-weight:600;

    &:focus{
      outline:none;
    }
`

export const CTAMobile = styled.a`
    background: ${orange.extra};
    border:none;
    border-radius:8px;
    width:100%;
    height:48px;
    color: ${white};
    font-size:14px;
    line-height:17px;
    font-weight:600;

    &:focus{
      outline:none;
    }
`

export const ButtonReverse = styled.a`
    border: 1px solid ${orange.extra};
    border-radius:8px;
    height:48px;
    width:100%;
    font-size:14px;
    line-height:17px;
    font-weight:600;
    color:${orange.extra};
    margin-top:18px ;
    display:flex;
    align-items:center;
    justify-content:center ;
    cursor: pointer;

    &:focus, &:hover{
      outline:none;
      text-decoration:none;
      color: #fff;
    }

    @media (min-width: ${breakpoints.md}){
      margin-top:17px;
    }
`
