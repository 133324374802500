import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      seeHowToParticipateFirst: imageSharp(fluid: {originalName: { regex: "/see-how-to-participate-first/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seeHowToParticipateSecund: imageSharp(fluid: {originalName: { regex: "/see-how-to-participate-secund/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seeHowToParticipateThird: imageSharp(fluid: {originalName: { regex: "/see-how-to-participate-third/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      seeHowToParticipateFouth: imageSharp(fluid: {originalName: { regex: "/see-how-to-participate-fouth/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      accessTheTab: imageSharp(fluid: {originalName: { regex: "/access-the-tab/" }}) {
        fluid(maxWidth: 578, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      playVideo: imageSharp(fluid: {originalName: { regex: "/playVideo/" }}) {
        fluid(maxWidth: 440, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
