import React, { PropsWithChildren, ReactElement } from 'react'
import IcHeartFill from '@interco/icons/core/action-navigation/ic_heart_fill'

const Step = ({ children }: PropsWithChildren) => {
  return (
    <>
      {children}
    </>
  )
}

const HowToParticipate: ReactElement[] = [
  <Step key='step-1'>
    <h2 className='fs-16 lh-20 fs-lg-24 lh-lg-28 text-orange--extra fw-500 font-citrina mb-3'>Veja como participar:</h2>
    <p className='fs-24 lh-28 fs-lg-40 lh-lg-48 fw-700 font-citrina'>1 -  Acesse o "Indique <span className='d-lg-block'>e Ganhe" na home do</span> Super App</p>
  </Step>,
  <Step key='step-2'>
    <p className='fs-24 lh-28 fs-lg-40 lh-lg-48 fw-700 font-citrina'>2 -  Compartilhe o seu <span className='d-lg-block'>código com o seus amigos</span></p>
  </Step>,
  <Step key='step-3'>
    <p className='fs-24 lh-28 fs-lg-40 lh-lg-48 fw-700 font-citrina'>3 -  Seus amigos precisam <span className='d-lg-block'>inserir o código no final da</span> abertura de conta</p>
  </Step>,
  <Step key='step-4'>
    <div className='d-flex align-items-center justify-content-center justify-content-md-start mb-3'>
      <span className='fs-32 lh-38 fs-xl-40 lh-xl-48 fw-700 text-center font-citrina text-md-left above-title mb-0'>Pronto!</span>
      <IcHeartFill height={32} width={32} color='#FF7A00' />
    </div>
    <p className='fs-24 lh-28 fs-lg-40 lh-lg-48 fw-700 font-citrina text-center text-md-left mb-3'>Você e seu amigo ganharão <span className='text-orange--extra d-lg-block'>200 pontos Loop</span></p>
    <p className='fs-10 lh-12 fs-lg-14 lh-lg-18 fs-xl-18 lh-xl-22 text-grayscale--500 text-center text-md-left mb-0'>Para acompanhar as indicações feitas, acesse a aba
      <strong className='italic-text'>“Minhas indicações”</strong>. Os pontos podem ser trocados por benefícios na seção Loop do super app.
    </p>
  </Step>,
]

export default HowToParticipate
