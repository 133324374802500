import styled from 'styled-components'
import { brand, laranja } from 'src/styles/colors'
import { breakpoints, device } from 'src/styles/breakpoints'

export const BenefitsSection = styled.section`
  background-color: ${brand.sand};
  padding: 40px 0;
  display: flex;
  align-items: center;

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px 0;
  }

  h2 {
    color: ${laranja.terra};
  }

  .text-header {
    margin-bottom: 32px;

    h2 {
      margin-bottom: 16px;
    }
  }
`
export const Card = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  border-radius: 8px;

  &:not(.item-0) {
    padding-top: 24px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 24px 20px;
    min-height: 235px;

    .item-2, .item-3 {
      margin-bottom: 0;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 356px;
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.xl}) {
    padding: 26px 30px;
  }

  .icon {
    margin-right: 10px;

    @media (min-width: ${breakpoints.md}) {
      margin-right: 0;
      margin-bottom: 16px;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  .header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 8px;
      display: block;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: 16px;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-bottom: 24px;
    }
  }

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
