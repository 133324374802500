import styled from 'styled-components'
import { brand, brandPj, orange } from 'src/styles/colors'

export const SeeHowToParticipateSection = styled.section`
   border-top:3px solid #F5F6FA;
   background-color: ${brand.sand};

   p, .above-title {
      color: ${brandPj.brown};
   }

   h2 {
      color: ${orange[500]};
   }

   .italic-text {
      font-style: italic;
   }

   .react-multi-carousel-dot {
      button {
         background-color: ${orange.extra};
      }
   }
`
