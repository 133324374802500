import React from 'react'
import * as S from './style'

const HowThePromotionWorks = () => {
  return (
    <S.HowThePromotionWorksSection className='d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6 col-xl-5 pr-lg-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-white fw-600 mb-4 mb-md-0'>
              Como funciona a promoção
              do <span className='text-orange--extra'>Indique e ganhe</span>?
            </h2>
          </div>
          <div className='col-12 col-md-6 offset-xl-1'>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-white'>
              Indicando o Inter você e seu amigo ganham 200 pontos no Inter Loop para usar como quiser.
              <br /><br />
              Quanto mais amigos indicar, mais você vai ganhar, podendo acumular <strong>até 40.000 (quarenta mil) pontos Loop</strong>.
            </p>
          </div>
        </div>
      </div>
    </S.HowThePromotionWorksSection>
  )
}

export default HowThePromotionWorks
