import React from 'react'

import Img from 'gatsby-image'

import * as S from './style'

import pageQuery from '../../pageQuery'

const AccessTheTab = () => {
  const data = pageQuery()
  return (
    <S.AccessTheTabSection className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center jusitfy-content-center'>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 order-md-last'>
            <h2 className='fs-16 lh-20 fs-lg-24 lh-lg-30 fw-500 d-block mt-4 mb-2 above-title font-citrina'>Como acompanhar minhas indicações?</h2>
            <p className='brown-title fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 mb-4'>Acesse a aba <span className='d-lg-block'>"Minhas indicações"</span></p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 mt-2 mb-4 text-grayscale--500'>Os pontos Loop podem ser trocados na seção "Programa de Pontos" do Super App.</p>
          </div>
          <div className='col-12 col-md-6 col-lg-5'>
            <Img fluid={data.accessTheTab.fluid} alt='tela de celular mostrando a seção de minhas indicações no super app Inter' />
          </div>
        </div>
      </div>
    </S.AccessTheTabSection>
  )
}

export default AccessTheTab
