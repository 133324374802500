import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'

import Layout from 'src/layouts/BaseLayout'

import { Wrapper } from './style'

import pageContext from './pageContext.json'

import Hero from './_sections/_hero/_index'
import BeInter from './_sections/_be-inter/_index'
import Benefits from './_sections/_benefits/_index'
import HowThePromotionWorks from './_sections/_how-the-promotion-works/_index'
import SeeHowToParticipate from './_sections/_see-how-to-participate/_indext'
import AccessTheTab from './_sections/_access-the-tab/_index'
import WhenWillIReceive from './_sections/_when-will-i-receive/_index'
import Faq from './_sections/_faq/_index'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import QrCode from './assets/image/qrcode_lp_indiqueeganhe.png'
import OpenAccountRightForm from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const IndiqueEGanhe = () => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ modalOpenYourAccount, setModalOpenYourAccount ] = useState(false)

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    element_action: 'click button',
    element_name: 'Indique e ganhe R$10 de cashback extra nas melhores lojas do Brasil!',
    section_name: 'Abra sua conta',
  })
  const domReady = useDomReady()

  const modalOpen = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse o <span class="text-orange--extra">Super App</span> para convidar os seus amigos.'
        subtitle='Convide seus amigos para simplificar os dias com a nossa Conta Digital gratuita e ganhe pontos Inter Loop para usar como quiser.'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o Super App do Inter.'
        qrCode={QrCode}
      />
    </Modal>
  )

  const openAccountModal = domReady && (
    <Modal
      isModalOpen={modalOpenYourAccount}
      setIsModalOpen={setModalOpenYourAccount}
      locationToRender={document.body}
    >
      {modalOpenYourAccount && (
        <OpenAccountRightForm
          closeModal={() => setModalOpenYourAccount(false)}
          dataLayer={dataLayer}
        />
      )}
    </Modal>
  )

  return (
    <Wrapper>
      {modalOpen}
      {openAccountModal}
      <Layout pageContext={pageContext}>
        <Hero setIsOpen={setIsOpen} setIsModalOpen={setModalOpenYourAccount} setDataLayer={setDataLayer} />
        <BeInter />
        <Benefits />
        <HowThePromotionWorks />
        <SeeHowToParticipate />
        <AccessTheTab />
        <WhenWillIReceive />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default IndiqueEGanhe
