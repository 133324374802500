import styled from 'styled-components'
import { brand, brandPj, orange } from 'src/styles/colors'

export const AccessTheTabSection = styled.section`
  background-color: ${brand.sand};

  .brown-title {
    color: ${brandPj.brown};
  }

  .above-title {
    color: ${orange[500]};
  }
`
