import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import { laranja, orange } from 'src/styles/colors'

export const WhenWillIReceiveSection = styled.section`
    background-image:url(https://central-imagens.bancointer.com.br/images-without-small-versions/background-quando-vou-receber-cashback-mobile/image.webp);
    background-color: ${orange.extra};
    background-repeat: no-repeat;
    background-size:contain;
    min-height: 600px;

    .above-title {
      color: ${laranja.terra};
    }


    @media (min-width: ${breakpoints.md}){
      background-image:url(https://central-imagens.bancointer.com.br/images-without-small-versions/background-quando-vou-receber-cashback-md/image.webp);
      background-repeat: no-repeat;
      background-color:${orange[500]};
      background-size:contain;
      min-height:542px;
    }

    @media (min-width: ${breakpoints.lg}){
      background-image:url(https://central-imagens.bancointer.com.br/images-without-small-versions/background-quando-vou-receber-cashback-lg/image.webp);
      background-repeat: no-repeat;
      background-size:contain;
    }

    @media (min-width: ${breakpoints.xl}){
      background-image:url(https://central-imagens.bancointer.com.br/images-without-small-versions/background-quando-vou-receber-cashback-xl/image.webp);
      background-repeat: no-repeat;
      background-size:contain;
    }

`
