import React from 'react'

import FAQ from 'src/components/Faq'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import pageContext from '../../pageContext.json'

const Faq = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lh-40 fs-xl-40 lg-xl-50 text-grayscale--500 fw-600 mb-3 text-center'>Ficou com alguma dúvida?</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-4 text-center'>Se a sua dúvida não estiver nas perguntas frequentes, fale com a gente!</p>
            <FAQ answerData={pageContext.structuredData.faq} columns={{ lg: 1, xl: 1 }} />
            <p className='fs-18 lh-22 text-grayscale--500 text-center mt-5'>Não encontrou sua dúvida?
              <a
                href='https://ajuda.bancointer.com.br/pt-BR/'
                target='__blank'
                className='text-orange--extra fw-600'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    element_action: 'click button',
                    element_name: 'Ficou com alguma dúvida?',
                    section_name: 'Fale com a gente',
                    redirect_url: 'https://ajuda.bancointer.com.br/pt-BR/',
                  })
                }}
              >
                {' '}Fale com a gente
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Faq
