import styled from 'styled-components'
import { white, orange, laranja } from 'src/styles/colors'

export const Card = styled.div`
  border: 1px solid ${orange.extra};
  background-color: ${white};
  border-radius:8px;
  padding: 36px;
  height: 259px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const BeInterSection = styled.section`
  .react-multi-carousel-dot--active {
    button {
      background: ${white}!important;
    }
  }
  .react-multiple-carousel__arrow {
    svg {
      fill: ${white}!important;
    }
  }

  .above-title {
    color: ${laranja.terra};
  }
`
