import React, { useState, useLayoutEffect, ReactElement } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import * as S from './style'

import Img from 'gatsby-image'

import pageQuery from '../../pageQuery'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import HowToParticipate from '../../assets/data/_how-to-participate'

type howToParticipateJSON = {
  text: string;
}

const images = [
  {
    src: 'seeHowToParticipateFirst',
    alt: 'tela de celular mostrando a home do super app Inter',
  },
  {
    src: 'seeHowToParticipateSecund',
    alt: 'tela de celular mostrando o modal de compartilhamento do código Indique e Ganhe',
  },
  {
    src: 'seeHowToParticipateThird',
    alt: 'tela de celular mostrando a tela de Indique e Ganhe na abertura de conta Inter',
  },
  {
    src: 'seeHowToParticipateFouth',
    alt: '',
  },
]

const WIDTH_MD = 768

const SeeHowToParticipate = () => {
  const data = pageQuery()
  const [ position, setPosition ] = useState(0)
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <S.SeeHowToParticipateSection className='py-5'>
      <div className='container'>
        <div className='row d-flex justify-content-between align-items-center'>
          <div className='col-12 col-md-6'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              beforeChange={(event: number) => setPosition(event)}
            >
              {
                HowToParticipate.map((item: ReactElement) => (
                  <>
                    {item}
                    {
                      isMobile && (
                        <Img className='mt-4' fluid={data[images[position].src].fluid} alt={images[position].alt} />
                      )
                    }
                  </>
                ))
              }
            </DefaultCarousel>
          </div>
          {
            !isMobile && (
              <div className='col-12 col-md-6 col-lg-5'>
                <Img fluid={data[images[position].src].fluid} alt={images[position].alt} />
              </div>
            )
          }
        </div>
      </div>
    </S.SeeHowToParticipateSection>
  )
}

export default SeeHowToParticipate
