import React from 'react'

import { orange } from 'src/styles/colors'
import benefitsData from '../../assets/data/benefits.json'

import { widths } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import InterCoIcons from 'src/components/UI/MarkdownIcon/InterCoIcons'

import * as S from './style'

type benefitsDataProps = {
  icon: string;
  title: string;
  descriptions: string[];
}

const Benefits = () => {
  const width = useWidth(300)

  return (
    <S.BenefitsSection>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='text-header text-md-center'>
              <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-700'>
                <span className='d-lg-block'>Programa de vantagens gratuito, </span><span className='text-orange--extra'>simples</span> e do seu jeito
              </h2>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0'>
                Muito mais que cashback: é liberdade para simplificar a vida como você quiser.
              </p>
            </div>

            <div className='row'>
              {
                benefitsData.map((item: benefitsDataProps, index: number) => (
                  <div className='col-12 col-md-6 col-lg-3' key={item.icon}>
                    <S.Card className={`px-3 py-3 bg-white item-${index}`}>
                      <div className='header'>
                        <div className='icon'>
                          <InterCoIcons
                            icon={item.icon}
                            size='MD'
                            color={orange.extra}
                            width={width < widths.md ? 20 : 32}
                            height={width < widths.md ? 20 : 32}
                          />
                        </div>
                        <h3 className='fs-16 lh-20 fs-lg-20 lh-lg-25 fw-600 text-grayscale--500 mb-0'>{item.title}</h3>
                      </div>
                      {
                        item.descriptions.map((description: string) => (
                          <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--400' key={description}>
                            {description}
                          </p>
                        ))
                      }
                    </S.Card>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </S.BenefitsSection>
  )
}

export default Benefits
