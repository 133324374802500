import React from 'react'

import * as S from './style'

const WhenWillIReceive = () => {
  return (
    <S.WhenWillIReceiveSection role='img' aria-label='a imagem mostra vários amigos sorrindo e olhando para um celular' className='py-5 d-flex align-items-md-center align-items-end '>
      <div className='container'>
        <div className='row d-flex justify-content-end'>
          <div className='col-12 col-md-6 col-lg-4 col-xl-5'>
            <h2 className='fs-16 lh-20 fs-md-24 lh-md-30 fw-700 above-title mb-3 d-block font-citrina'>Quando vou receber?</h2>
            <p className='brown-title fs-24 lh-30 fs-md-32 lh-md-38 fs-xl-40 lh-xl-48 text-white fw-600 mb-3'>Em até 10 dias após a <span className='d-lg-block'>abertura de conta do</span> indicado</p>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 text-white mt-0'>Sempre que um amigo abrir conta usando seu código, você receberá, em até 10 dias, os pontos Loop.</p>
          </div>
        </div>
      </div>
    </S.WhenWillIReceiveSection>
  )
}

export default WhenWillIReceive
