import { device } from 'src/styles/breakpoints'
import { brandPj } from 'src/styles/colors'
import styled from 'styled-components'

export const HowThePromotionWorksSection = styled.section`
  background-color: ${brandPj.brown};
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 80px 0;
  }

  @media ${device.desktopLG} {
    padding: 84px 0;
  }

  @media ${device.desktopXL} {
    padding: 118px 0;
  }
`
