import React from 'react'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import * as S from './style'

import beInterJSON from '../../assets/data/be-inter.json'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

type CardProps = {
  icon: string;
  text: string;
}

const BeInter = () => {
  return (
    <S.BeInterSection className='py-5 bg-orange--extra'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <span className='fs-16 lh-20 fs-md-24 lh-md-30 font-citrina fw-700 above-title'>Ser Inter é assim: </span>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-48  text-white fw-600 mb-4'>
              Indicar e ganhar muito!
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white'>Convide seus amigos para simplificar os dias com a nossa Conta Digital gratuita e ganhe pontos Inter Loop para usar como quiser.</p>
          </div>
          <div className='col-12 col-md-6'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1, partialVisibilityGutter: 50 }}
              lg={{ items: 1, partialVisibilityGutter: 150 }}
              xl={{ items: 2 }}
            >
              {
              beInterJSON.map((item: CardProps) => (
                <div className='mx-2' key={item.icon}>
                  <S.Card>
                    <OrangeDsIcon icon={item.icon} size='MD' color='#161616' />
                    <h3 className='fs-24 lh-30 text-grayscale--500 fw-600 mb-0' dangerouslySetInnerHTML={{ __html: item.text }} />
                  </S.Card>
                </div>
              ))
            }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </S.BeInterSection>
  )
}

export default BeInter
